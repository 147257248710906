import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  extraTicketDetailsListResponse,
  ticketDetailsListResponse,
  ticketSubTypeListResponse,
  ticketingCardResponse,
} from "../@Types/DRO/SosTicketingResponse";
import { OrderResponse } from "../@Types/DRO/CancelOrderResponse";
export class SosTicketingApi {
  public static api = new SosTicketingApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-micro.bcykal.com",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  ticketCardDetails(
    startDate: string | undefined,
    endDate: string | undefined
  ): Promise<AxiosResponse<ticketingCardResponse>> {
    return this.instance.get<ticketingCardResponse>(
      "/mobycy/on-demand/dashboard/v1/ticket-header",
      {
        params: {
          startDate,
          endDate,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  ticketDetailsList(
    ticketTypeId: number,
    startDate: string | undefined,
    endDate: string | undefined,
    status: string,
    page: number,
    searchParam: string,
    pageSize: number = 20
  ): Promise<AxiosResponse<ticketDetailsListResponse>> {
    let apiUrl = "/mobycy/on-demand/dashboard/v1/ticket-list";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance.get<ticketDetailsListResponse>(apiUrl, {
      params: {
        ticketTypeId,
        startDate,
        endDate,
        status,
        page,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  extraTicketDetailsList(
    ticketId: number
  ): Promise<AxiosResponse<extraTicketDetailsListResponse>> {
    let apiUrl = "/mobycy/on-demand/dashboard/v1/ticket-details";
    return this.instance.get<extraTicketDetailsListResponse>(apiUrl, {
      params: {
        ticketId,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  subTypeList(): Promise<AxiosResponse<ticketSubTypeListResponse>> {
    return this.instance.get<ticketSubTypeListResponse>(
      "/mobycy/on-demand/dashboard/v1/get/ticket-types",
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  assignTicket(ticketId: number): Promise<any> {
    return this.instance
      .post<any>(
        `/mobycy/on-demand/dashboard/v1/ticket/assign`,
        {
          ticketId,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  addTicketComment(ticketId: number, remarks: string): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/ticket/add-comment`,
        {
          ticketId,
          remarks,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  closeTicket(ticketId: number, remarks: string): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/ticket/close`,
        {
          ticketId,
          remarks,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  cancelTicket(
    ticketId: number,

    remarks: string
  ): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/ticket/cancel`,
        {
          ticketId,
          remarks,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  updateTicketType(
    ticketId: number,
    newTicketType: number,
    remarks: string
  ): Promise<OrderResponse> {
    return this.instance
      .post<OrderResponse>(
        `/mobycy/on-demand/dashboard/v1/ticket/change-type`,
        {
          ticketId,
          newTicketType,
          remarks,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}

import axios, { AxiosInstance, AxiosResponse } from "axios";
import { blacklistedRidersResponseProps } from "../@Types/DRO/BlacklistedRidersResponse";
export class BlacklistedRidersApi {
  public static api = new BlacklistedRidersApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://stag-micro.bcykal.com/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  blacklistedRidersList(
    selectedStatus: string,
    page: number,
    searchParam: string,
    pageSize: number = 20
  ): Promise<AxiosResponse<blacklistedRidersResponseProps>> {
    let apiUrl = "mobycy/on-demand/dashboard/v1/rider/restrict/details";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    return this.instance.get<blacklistedRidersResponseProps>(apiUrl, {
      params: {
        page: page,
        pageSize: pageSize,
        status: selectedStatus,
      },
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  banRiders(requestType: string, data: any): Promise<any> {
    return this.instance
      .post<any>(
        `mobycy/on-demand/dashboard/v1/rider/restrict`,
        {
          requestType,
          data,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  unbanRiders(
    requestType: string,
    userId: number,
    releaseRemark: string
  ): Promise<any> {
    return this.instance
      .post<any>(
        `mobycy/on-demand/dashboard/v1/rider/release`,
        {
          requestType,
          userId,
          releaseRemark,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
